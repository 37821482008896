﻿window.log = function () {
    log.history = log.history || [];   // store logs to an array for reference
    log.history.push(arguments);
    if (this.console) {
        arguments.callee = arguments.callee.caller;
        var newarr = [].slice.call(arguments);
        (typeof console.log === 'object' ? log.apply.call(console.log, console, newarr) : console.log.apply(console, newarr));
    }
};

// Format for currency output. Use as app.config.globalProperties.$filter
function toCurrency(value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(value);
}

function stripSpaces(value) {
    if (value.length > 0) {
        return value.replace(/\s/g, "");

    }
}


// *** Example Functions ***

//***Async Fetch Call
//response = await fetch("/Account/ManagePayment", {
//    method: "POST",
//    headers: {
//        "Accept": "application/json",
//        "Content-Type": "application/json"
//    },
//    body: JSON.stringify(this.paymentInfo)
//});
//paymentChange = await response.json();
//log("managePayment result: ", paymentChange);

//****Promise Fetch Call
//fetch("/Store/AccountOrders", {
//    method: 'POST',
//    headers: {
//        'Accept': 'application/json',
//        'Content-Type': 'application/json'
//    },
//    body: JSON.stringify(call)
//}).then(response => {
//    return response.json();
//}).then((jsondata) => {
//    returnedData = jsondata;
//    log("loadOrderRows returned: ", returnedData);
//    if (returnedData.recordsFiltered > 0) {
//        this.noOrders = false;
//        this.manageOrderData(returnedData);
//    } else {
//        this.noOrders = true;
//        this.isLoading = false;
//    }
//});